<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="row">
      <div class="col-lg-9">
        <div class="card">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">
              Disburse Recipient
            </div>
            <div>
              <el-button :disabled="detail.status !== 'waiting_approval'" @click="showModalAddMore = true" type="info" size="mini"><font-awesome-icon icon="user-plus"/> Add more recipients</el-button>
              <el-button @click="downloadReport" type="primary" size="mini"><font-awesome-icon icon="download"/> Download</el-button>
            </div>
          </div>
          <div class="card-body">
            <el-skeleton :loading="loading_detail" :rows="6" animated />
            <table v-if="!loading_detail" class="table table-vcenter border-0 w-full">
              <thead>
                <tr>
                  <th>Recipient Name</th>
                  <th>Recipient Phone Number</th>
                  <th>Amount</th>
                  <th>Disburse Status</th>
                  <th>Refference Number</th>
                  <th v-if="detail.status == 'waiting_approval'">#</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>{{ truncate(item.name, { length: 20 }) }}</td>
                  <td>{{ item.phone_number }}</td>
                  <td>{{ item.amount_str }}</td>
                  <td>
                    <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
                  </td>
                  <td>{{ item.ref_no || '-' }}</td>
                  <th v-if="detail.status == 'waiting_approval'">
                    <el-button @click="handlerDeleteRecipient(item.id)" size="mini" circle type="danger"><font-awesome-icon icon="trash"/></el-button>
                  </th>
                </tr>
              </tbody>
            </table>
            <el-empty description="No data found" v-if="!loading_detail && total_rows == 0" :image="emptyStateImage"></el-empty>
          </div>
          <div class="card-footer d-flex justify-content-between">
            <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
            <div class="inline-table">
              <b-pagination
                class="table-cell"
                @change="pageChangeHandler"
                :per-page="per_page"
                :limit="5"
                :total-rows="total_rows"
                v-model="page"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <history-disburse :batch="{ id: $route.params.id }" @setBatchName="setBatchName" />
      </div>
      <add-more-recipient
        @onSubmit="getList"
        :batchId="$route.params.id"
        :companyId="detail.company_id"
        @onHide="showModalAddMore = false" :is-shown="showModalAddMore" />
    </div>
  </div>
</template>
<script>
import {
  GET_DETAIL_DISBURSEMENT, DOWNLOAD_DETAIL_DISBURSEMENT, DELETE_RECIPIENT_DISBUSEMENT,
} from '@/store/modules/disbursement';
import emptyStateImage from '@/assets/images/empty-state.png';
import HistoryDisburse from './HistoryDisburse.vue';
import { truncate } from 'lodash';
import AddMoreRecipient from './AddMoreRecipient.vue';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  components: { HistoryDisburse, AddMoreRecipient },
  name: 'DisburseRecipientList',
  props: {
    isShown: {
      type: Boolean,
      default: true,
    },
    batch: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      truncate,
      emptyStateImage,
      loading_detail: false,
      showModalAddMore: false,
      list: [],
      total_rows: 0,
      page: 1,
      per_page: 10,
      show: false,
      loader: null,
      batch_name: 'Disburse Report',
      detail: {},
    };
  },
  async mounted() {
    const l = this.$loading.show();
    await this.getList();
    l.hide();
  },
  methods: {
    async getList() {
      this.loading_detail = true;
      await this.$store.dispatch(GET_DETAIL_DISBURSEMENT, {
        id: this.$route.params.id,
        query: {
          page: this.page,
          per_page: this.per_page,
        },
      });
      this.loading_detail = false;
      const response = this.$store.getters.disburse_recipient;
      this.batch_name = response.detail.batch_name;
      this.detail = response.detail;
      this.list = response.rows;
      this.total_rows = response.count;
      if (this.list.length > 0 && this.list[0].name) {
        this.list.sort((a, b) => {
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      } else {
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    onHide() {
      this.$emit('onHide');
    },
    async downloadReport() {
      this.loader = this.$loading.show();
      await this.$store.dispatch(DOWNLOAD_DETAIL_DISBURSEMENT, {
        id: this.$route.params.id,
      }).then((data) => {
        // console.log(data);
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
        anchor.target = '_blank';
        anchor.download = `${this.batch_name}.csv`;
        anchor.click();
      });
      this.loader.hide();
    },
    setBatchName(name) {
      this.batch_name = name;
    },
    handlerDeleteRecipient(recipient_id) {
      this.$confirm('Are you sure want to remove this recipient?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // console.log('tobe deleted', recipient_id);
            await this.$store.dispatch(DELETE_RECIPIENT_DISBUSEMENT, {
              id: this.$route.params.id,
              data: {
                recipient_id,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Sucessfully create disbursement request',
              });
              this.getList();
            }).catch((err) => {
              if (err.response && err.response.data) {
                popupErrorMessages(err.response.data);
              }
            });
            instance.confirmButtonLoading = false;
            done();
          }
          done();
        },
      }).catch(() => {});
    },
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  watch: {
    isShown() {
      this.loading_detail = this.isShown;
      this.show = this.isShown;
      if (this.isShown) {
        this.getList();
      }
    },
  },
};
</script>
