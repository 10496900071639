<template>
  <b-modal size="xl" v-model="show" title="Choose Recipient" hide-footer @hide="onHide" body-class="p-0">
    <div class="card">
      <div class="card-header justify-content-between">
        <div class="card-title">
          App User
        </div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <b-input style="width: 200px" size="sm" type="text" v-model="user_filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
          <el-select size="small" v-model="user_filter.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user_filter.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
          <el-button v-b-modal.modal class="mr-2 ml-2" size="small" type="primary">Input Amount</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <el-table :data="list"
          ref="list_table"
          v-loading="loading.table"
          @selection-change="handlerSelectionChange"
          class="table card-table text-nowrap table-vcenter"
          style="width: 100%">
          <el-table-column type="selection" width="65"/>
          <el-table-column width="200" prop="full_name" label="Name" :sortable="true"/>
          <el-table-column width="200" prop="company_name" label="Company"></el-table-column>
          <el-table-column min-width="250" prop="email" label="Email"></el-table-column>
          <el-table-column width="150" prop="phone_number" label="Phone Number"></el-table-column>
          <el-table-column width="150" prop="created_at_str" label="Join Date"></el-table-column>
          <el-table-column width="150" prop="login_at_str" label="Last Login Date"></el-table-column>
          <el-table-column width="120" prop="private_status" label="Account Type">
            <template slot-scope="item">
              <span class="tag" :class="item.row.private_status_color">{{ item.row.private_status }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="private_status" label="KYC Status">
            <template slot-scope="item">
              <span class="tag" :class="item.row.kyc_status_color">{{ item.row.kyc_status_str }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="status" label="Status">
            <template slot-scope="item">
              <span class="tag" :class="item.row.status_color">{{ item.row.status_str }}</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <el-empty description="No data found" :image="emptyStateImage"></el-empty>
          </template>
        </el-table>
      </div>
      <div class="d-flex justify-content-between p-2">
        <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
    </div>
    <br>
    <div class="d-flex justify-content-between p-2">
    <div class="inline-table">
    <b-modal v-model="showModalAmount" id="modal" size="sm"  title="Input amount received" hide-footer @hide="formAmount.amount = ''">
      <b-form @submit.prevent="onSubmitAmount">
        <b-input
          v-model="formattedNew"
          placeholder="Input amount"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.formAmount.amount.$invalid" v-if="v$.formAmount.amount.$errors[0]">
          <span>{{ v$.formAmount.amount.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form>
      <div class="d-flex float-right mt-2">
        <el-button @click="onSubmitAmount" class="mr-2 ml-2" size="small" type="primary">Submit Amount</el-button>
        <el-button @click="showModalAmount = false" size="small" class="mr-2 ml-2">Cancel</el-button>
      </div>
    </b-modal>
    </div>
    </div>
    <br>
    <div class="float-right p-2">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary" :disabled="isAmountEmpty">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2 ml-2">Cancel</el-button>
      <br>
      <span v-if="isAmountEmpty" class="text-danger">Click input amount!</span>
    </div>
  </b-modal>
</template>
<script>
import { uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric,
} from '@vuelidate/validators';
import {
  GET_APPUSERS,
} from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';

export default {
  name: 'DisburseRecipientTable',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    currentSelected: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      formAmount: {
        amount: { required, numeric },
      },
    };
  },
  data() {
    return {
      amountSubmitted: false,
      selectedRows: {}, 
      emptyStateImage,
      loading: {
        table: false,
        submit: false,
      },
      formAmount: {
        amount: '',
      },
      show: false,
      page: 1,
      per_page: 10,
      total_rows: 0,
      list: [],
      isLoading: false,
      search_by: '',
      search_keyword: 'full_name',
      temp_checked: {},
      checked: {},
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      showModalAmount: false,
      // temp_selected: {},
      // selected
      temp_selected: []
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    async getList() {
      this.loading.table = true;
      await this.$store.dispatch(GET_APPUSERS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        company_id: this.companyId,
        order_by: "full_name:asc"
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;

      this.total_rows = count || 0;
      this.list = rows;
      this.loading.table = false;
    },
    async pageChangeHandler(page) {
      this.checked[`page${this.page}`] = this.temp_checked[`page${this.page}`] || [];

      this.page = page;
      await this.getList();
      if (this.checked[`page${this.page}`]) {
        this.checked[`page${this.page}`].forEach((v) => {
          const obj = this.list.find((k) => k.owned_by === v.owned_by);
          if (obj) {
            // console.log('toggle', obj);
            setTimeout(() => {
              this.$refs.list_table.toggleRowSelection(obj);
            }, 100);
          }
        });
      }
    },
    handlerSelectionChange(value) {
      this.temp_selected.push(...value); 
      // this.showModalAmount = true;
      this.$set(this.temp_checked, `page${this.page}`, value);
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getList();
    },
    onSubmit() {
      let data = [];
      this.temp_selected.forEach((item) => {
        data.push(item);
      });
      if (data.length > 0) {
        data = uniqBy(data, 'owned_by');
        this.$emit('onSubmit', data);
      } else {
      }
      this.show = false;
    },
    onSubmitAmount() {
      this.v$.formAmount.$touch();
      if (this.v$.formAmount.$error) return;
      if (Array.isArray(this.temp_selected)) {
        if (this.temp_selected.length > 1) {
          this.temp_selected.forEach(selectedItem => {
            selectedItem.amount = parseInt(this.formAmount.amount, 0);
          });
        } else if (this.temp_selected.length === 1) {
          this.temp_selected[0].amount = parseInt(this.formAmount.amount, 0);
        }
      } else {
      }
      this.showModalAmount = false;
      this.amountSubmitted = true;
    },
    formatNumber(value) {
      if (typeof value !== 'string') {
        value = String(value);
      }
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    parseFormattedNumber(value) {
      return value.replace(/,/g, '');
    },
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isAmountEmpty() {
      return !this.formAmount.amount.trim() && !this.amountSubmitted;
    },
    formattedNew: {
      get() {
        return this.formatNumber(this.formAmount.amount);
      },
      set(value) {
        this.formAmount.amount = this.parseFormattedNumber(value);
      },
    },
  },
  watch: {
    isShow() {
      this.show = this.isShow;
      if (this.show) {
        this.getList();
      }
    },
    list: {
      handler(newValue, oldValue) {
        if (this.selectedRows[`page${this.page}`]) {
          const previousSelection = this.selectedRows[`page${this.page}`];
          const newSelection = previousSelection.filter(row => newValue.find(newRow => newRow.owned_by === row.owned_by));
          this.$set(this.selectedRows, `page${this.page}`, newSelection);
        }
      },
      deep: true,
    },
  },
};
</script>