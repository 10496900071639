<template>
  <div class="card">
    <div class="card-header border-0 justify-content-between">
      <div class="card-title">
        History
      </div>
    </div>
    <div class="card-body" style="max-height: 720px; overflow-y: auto;">
      <el-skeleton :loading="loading.history" :rows="6" animated />
      <el-timeline v-if="total_rows > 0 && !loading.history">
        <el-timeline-item v-for="item in list" :timestamp="item.created_at_str"
          placement="top"
          :color="item.dot_color"
          :type="item.dot_color"
          :key="item.id">
          <el-card>
            <h4 :style="{ color: item.dot_color }">{{ item.action_str }}</h4>
            <p>{{ item.user_name }} {{ item.action_str.toLowerCase() }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <el-empty description="No history found" v-if="total_rows == 0 && !loading.history" :image="emptyStateImage"></el-empty>
    </div>
  </div>
</template>
<script>
import { GET_HISTORY_DISBURSEMENT } from '@/store/modules/disbursement';
import emptyStateImage from '@/assets/images/undraw/no-history.svg';

export default {
  name: 'HistoryDisburse',
  metaInfo: {
    title: 'Disbursement',
  },
  props: {
    batch: {
      type: Object,
      default() {
        return {};
      },
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyStateImage,
      show: false,
      loading: {
        history: false,
      },
      total_rows: 0,
      list: [],
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    async loadHistories() {
      this.loading.history = true;
      await this.$store.dispatch(GET_HISTORY_DISBURSEMENT, this.batch.id);
      this.loading.history = false;
      const response = this.$store.getters.disbursement_histories;
      this.total_rows = response.rows.length;
      this.list = response.rows;
      // this.$emit('setBatchName', this.list[0].disbursement.batch_name);
    },
  },
  mounted() {
    this.loadHistories();
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.loadHistories();
      }
    },
  },
};
</script>