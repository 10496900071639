<template>
  <b-modal v-model="show" title="Add More Recipient" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Recipient from">
        <el-select @change="recipientFromHandler" class="mb-2 w-100" v-model="recipient_from" placeholder="Select recipient input source">
          <el-option label="File" value="file"></el-option>
          <el-option label="Choose from Existing User" value="select"></el-option>
        </el-select>
      </b-form-group>
      <b-form-group label="Recipient File" v-if="recipient_from == 'file'">
        <b-form-file
          v-model="v$.form.file.$model"
          @input="handlerInputFile"
          :state="Boolean(v$.form.file.$model)"
          class="mt-2 border-1 p-2 text-sm"
          accept=".csv"
          placeholder="Choose a file or drop it here..."
          drop-placeholder=" "
        ></b-form-file>
        <el-button size="mini" class="mt-2" type="info" @click="handlerBtnValidate" :disabled="!Boolean(v$.form.file.$model)">Validate Recipient</el-button>
        <!-- <b-form-invalid-feedback :state="!v$.form.file.$invalid" v-if="v$.form.file.$errors[0]">
          <span>{{ v$.form.file.$errors[0].$message }}</span>
        </b-form-invalid-feedback> -->
      </b-form-group>
      <b-form-group label="Choose Recipient" v-if="recipient_from == 'select'">
        <el-button @click="handlerChooseRecipient" size="small">Select recipient</el-button>
        <span v-if="form.details.length > 0" style="line-height: 25px"><br/>
          Total recipient: <b>{{ form.details.length }}</b><br/>
          Total amount: <b>Rp.{{ sumBy(form.details, 'amount').toLocaleString() }}</b>
        </span>
        <!-- <b-form-invalid-feedback :state="!v$.form.details.$invalid" v-if="v$.form.details.$errors[0]">
          <span>{{ v$.form.details.$errors[0].$message }}</span>
        </b-form-invalid-feedback> -->
      </b-form-group>
      <!-- <b-form-group label="OTP">
        <el-input @keydown.native="handlerInput" show-word-limit maxlength="6" v-model="v$.form.otp.$model" type="text" placeholder="Input 6 length authentication code"/>
        <b-form-invalid-feedback :state="!v$.form.otp.$invalid" v-if="v$.form.otp.$errors[0]">
          <span>{{ v$.form.otp.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group> -->
    </b-form>
    <div class="d-flex float-right">
      <el-button :disabled="!validatedRecipient" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
    <recipient-table
      @onSubmit="onSubmitRecipientTable"
      @onHide="showModalRecipientTable = false"
      :company-id="companyId.toString()"
      :is-show="showModalRecipientTable" />
    <preview-recipient-list
      @onHide="showModalPreviewRecipient = false"
      @onSubmit="validatedRecipient = true"
      :recipients="csv_data"
      :company-id="companyId.toString()"
      :is-show="showModalPreviewRecipient" />
  </b-modal>
</template>
<script>
import { sumBy, uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, requiredIf,
} from '@vuelidate/validators';
import { GET_COMPANIES, CHECK_MBAYAR_BALANCE } from '@/store/modules/companies';
import { UPDATE_RECIPIENT_DISBUSEMENT } from '@/store/modules/disbursement';
import popupErrorMessages from '@/library/popup-error-messages';
import RecipientTable from './RecipientTable.vue';
import PreviewRecipientList from './PreviewRecipientList.vue';

export default {
  components: { RecipientTable, PreviewRecipientList },
  name: 'AddMoreRecipient',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    batchId: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
      form: {
        details: {},
      },
    };
  },
  validations() {
    return {
      form: {
        file: { required: requiredIf(this.recipient_from === 'file') },
        batch_details: { required },
      },
    };
  },
  data() {
    return {
      showModalPreviewRecipient: false,
      sumBy,
      loading: {
        submit: false,
        company: false,
        balance: false,
      },
      show: false,
      form: {
        company_id: '',
        otp: '',
        batch_name: '',
        file: null,
        details: [],
        batch_details: [],
      },
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
      company_balance: 0,
      recipient_from: 'file',
      showModalRecipientTable: false,
      csv_data: [],
      csv_length: 0,
      validatedRecipient: false,
    };
  },
  methods: {
    onHide() {
      this.resetForm();
      this.$emit('onHide');
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      this.companies = this.$store.getters.companies;
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    handlerInput(evt) {
      const theEvent = evt || window.event;
      let key = '';
      if ([8, 91].includes(theEvent.keyCode) || theEvent.ctrlKey || theEvent.metaKey) {
        return;
      }
      // Handle paste
      if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
      } else {
      // Handle key press
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      const regex = /[0-9]/;
      // console.log(key);
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    resetForm() {
      this.form = {
        company_id: '',
        otp: '',
        batch_name: '',
        file: null,
        details: [],
        batch_details: [],
      };
    },
    async onSubmit() {
      this.v$.form.$touch();
      let data = null;
      if (this.recipient_from === 'file') {
        this.form.batch_details = await this.parseFileCsv().catch(() => {});
      } else {
        data = this.form;
        delete data.file;
      }
      if (this.v$.form.$error) return;

      this.loading.submit = true;
      await this.$store.dispatch(UPDATE_RECIPIENT_DISBUSEMENT, {
        id: this.batchId,
        data: {
          batch_details: this.form.batch_details,
        },
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Sucessfully update disbursement request',
        });
        this.resetForm();
        this.show = false;
        this.$emit('onSubmit');
      }).catch((err) => {
        console.log(err);
        if (err.response && err.response.data) {
          const resp = err.response.data;
          if (resp.error.code === 1019) {
            this.form.otp = '';
            this.v$.form.$touch();
          }
          popupErrorMessages(err.response.data);
        }
      });
      this.loading.submit = false;
    },
    async checkBalance() {
      this.loading.balance = true;
      await this.$store.dispatch(CHECK_MBAYAR_BALANCE, {
        id: this.companyId,
      }).then(({ data }) => {
        this.company_balance = data.balance.balance;
      }).catch(() => {});
      this.loading.balance = false;
    },
    handlerChangeCompany() {
      this.checkBalance();
    },
    handlerChooseRecipient() {
      this.showModalRecipientTable = true;
    },
    onSubmitRecipientTable(data) {
      this.form.batch_details = data.map((v) => {
        const {
          phone_number, amount, full_name, owned_by,
        } = v;
        return {
          owned_by,
          phone_number,
          amount,
          name: full_name,
        };
      });
    },
    handlerBtnValidate() {
      if (!this.companyId) {
        this.$message({
          title: 'Oops',
          type: 'warning',
          message: 'Please select company before validate recipient.',
        });
        return;
      }
      this.showModalPreviewRecipient = true;
    },
    async handlerInputFile() {
      const l = this.$loading.show();
      try {
        if (!this.form.file || !this.companyId) {
          l.hide();
          return;
        }
        this.validatedRecipient = false;
        const obj = await this.parseFileCsv();
        this.csv_data = uniqBy(obj, 'phone_number');
        l.hide();
      } catch (error) {
        this.$message({
          title: 'Oops',
          type: 'warning',
          message: 'Failed read csv file.',
        });
        l.hide();
      }
    },
    recipientFromHandler(value) {
      this.validatedRecipient = value === 'select';
    },
    /* eslint-disable no-async-promise-executor */
    async parseFileCsv() {
      return new Promise(async (resolve, reject) => {
        try {
          const str = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(this.form.file);
            reader.onload = () => resolve(reader.result.replace(/\\r\\n|\\r|\\n|\\n\\r/igm, ''));
            reader.onerror = (error) => reject(error);
          });
          const csv_split = str.split('\n');
          /* eslint-disable no-param-reassign */
          const header = csv_split[0].split(/;|,/ig).map((v) => {
            v = v.replace(/\\r\\n|\\r|\\n|\\n\\r/ig, '').replace('\r', '');
            return v;
          });
          const obj = [];
          this.csv_length = csv_split.length;
          for (let i = 1; i <= csv_split.length; i += 1) {
            const obj_to_push = {};
            if (csv_split[i]) {
              const str_split = csv_split[i].split(/;|,/ig);
              header.forEach((k, ki) => {
                obj_to_push[k] = str_split[ki];
              });
              obj.push(obj_to_push);
            }
          }
          resolve(obj);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      this.resetForm();
      this.v$.form.$touch();
      if (this.show) {
        this.loadCompanies();
      }
    },
  },
};
</script>
<style>
.custom-file-label {
  font-size: .75rem;
}
</style>