<template>
  <b-modal size="xl" v-model="show" title="Validate Recipient List" hide-footer @hide="onHide" body-class="p-0">
    <div class="card">
      <div class="card-body">
        <b-alert variant="warning" show>To prevent failed on disbursement process, it is recommended to validate all the user on the table below. Any user who does not have wallet account can't receive disbursement. Click button below.<br/><br/><el-button @click="startValidation" :loading="loading.validation" class="mr-2 ml-2" size="mini" type="primary">Start validation</el-button></b-alert>
      </div>
      <div class="table-responsive">
        <el-table :data="listComputed"
          ref="list_table"
          class="table card-table text-nowrap table-vcenter"
          style="width: 100%">
          <el-table-column min-width="100" prop="phone_number" label="Phone Number"></el-table-column>
          <el-table-column min-width="100" prop="name" label="Name">
            <template slot-scope="item">
              <span :class="{ 'text-danger': item.row.status === 'invalid' }">{{ item.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="Amount"></el-table-column>
          <el-table-column min-width="90" prop="status" label="Status">
            <template slot-scope="item">
              <span @click="tagStatusHandler(item)" class="tag" :class="item.row.status_color">{{ item.row.status_str }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="account_name" label="Name on Wallet">
            <template slot-scope="item">
              <span :class="{ 'text-danger': item.row.status === 'invalid' }">{{ item.row.account_name }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="30" prop="account_name" label="#">
            <template slot-scope="item">
              <el-button @click="handlerDeleteRecipient(item.$index)" size="mini" circle type="danger"><font-awesome-icon icon="trash"/></el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <el-empty description="No data found" :image="emptyStateImage"></el-empty>
          </template>
        </el-table>
      </div>
      <div class="d-flex justify-content-between p-2">
        <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ list.length }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="list.length"
            v-model="page"
          />
        </div>
      </div>
    </div>
    <div class="float-right p-2">
      <el-button :disabled="!isValidated" @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Done</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
  </b-modal>
</template>
<script>
import {
  VALIDATE_RECIPIENT_DISBURSEMENT,
} from '@/store/modules/disbursement';
import {
  whilst,
} from 'async';
import emptyStateImage from '@/assets/images/empty-state.png';
import {
  cloneDeep,
} from 'lodash';

const status_color = {
  waiting: 'tag-default',
  valid: 'tag-success',
  no_mbayar_wallet: 'tag-warning',
  not_venteny_user: 'tag-danger',
  check_failed: 'tag-danger',
  valid_not_venteny_user: 'tag-indigo',
  invalid: 'tag-danger',
};
const status_str = {
  waiting: 'Ready',
  valid: 'Valid',
  no_mbayar_wallet: 'No Wallet',
  not_venteny_user: 'Not VENTENY user',
  check_failed: 'Failed',
  valid_not_venteny_user: 'Valid but not VENTENY user',
  invalid: 'Invalid',
};

export default {
  name: 'PreviewRecipientList',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    recipients: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      emptyStateImage,
      loading: {
        submit: false,
        validation: false,
      },
      show: false,
      isValidated: false,
      list: [],
      page: 1,
      per_page: 10,
      total_page: 1,
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
      this.isValidated = false;
      this.page = 1;
    },
    pageChangeHandler(page) {
      this.page = page;
    },
    async startValidation() {
      // let stop = false;
      this.loading.validation = true;
      whilst(
        (cb) => {
          cb(null, this.page <= this.total_page);
        },
        (cb) => {
          this.validateRecipient(() => {
            this.page += 1;
            cb();
          });
        },
        () => {
          this.loading.validation = false;
          this.page = 1;
          this.isValidated = true;
        },
      );
    },
    async validateRecipient(cb) {
      await this.$store.dispatch(VALIDATE_RECIPIENT_DISBURSEMENT, {
        company_id: this.companyId,
        recipients: this.listComputed,
      }).then((response) => {
        response.result.forEach((v) => {
          const i = this.list.findIndex((l) => l.phone_number === v.phone_number);
          const obj = cloneDeep(this.list[i]);
          if (v.status === 'valid' && obj.name.toLowerCase() !== v.account_name.toLowerCase()) {
            v.status = 'invalid';
          }
          obj.status = v.status;
          obj.status_color = status_color[v.status];
          obj.status_str = status_str[v.status];
          obj.account_name = v.account_name;
          this.$set(this.list, i, obj);
          console.log(this.list[i]);
        });
      }).catch((err) => console.log(err));
      cb();
    },
    onSubmit() {
      this.$emit('onSubmit', this.list);
      this.$emit('onHide');
    },
    tagStatusHandler(item) {
      const obj = item.row;
      if (obj.status !== 'invalid') {
        return;
      }

      this.$confirm('Mark this recipient as a valid recipient?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            obj.status = 'valid';
            obj.status_color = status_color.valid;
            obj.status_str = status_str.valid;
            const offset = (this.page - 1) * this.per_page;
            this.$set(this.list, offset + item.$index, obj);
            done();
          }
          done();
        },
      }).catch(() => {});
    },
    handlerDeleteRecipient(index) {
      this.$confirm('Are you sure want to remove this recipient?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            this.list.splice(index, 1);
          }
          done();
        },
      });
    },
  },
  computed: {
    showingFrom() {
      return this.list.length ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.list.length) {
        if (this.list.length < this.per_page) {
          return this.list.length;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    listComputed() {
      const start = (this.page - 1) * this.per_page;
      const end = this.page * this.per_page;
      return this.list.slice(start, end);
    },
  },
  watch: {
    isShow() {
      this.show = this.isShow;
      if (this.show) {
        this.list = this.recipients.map((v) => {
          v.status = 'waiting';
          v.status_color = status_color[v.status];
          v.status_str = status_str[v.status];
          return v;
        });
        this.total_page = Math.ceil(this.list.length / this.per_page);
      }
    },
  },
};
</script>